<template>
  <queue-manager :content="feedContent" :layout="layout"></queue-manager>
</template>

<script>
export default {
  name: "QueueManagerFeed",
  components: {
    QueueManager: () => import("@/components/queuemanager/QueueManager"),
  },
  props: {
    feedContent: Object,
    layout: Object,
  },
  data() {
    return {
      //
    };
  },
  mounted() {
    //
  },
  computed: {
    //
  },
  methods: {
    //
  },
};
</script>
